import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const AppHomeCustomersReviewWpper = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 40px;
  text-align: center; /* Center the heading and slider */

  .HomeCustomersReviewContain {
    max-width: 95vw;
    margin: auto;
    background-color: #102c64;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 42px;
  }

  .sliderContainer {
    width: 80vw; /* Adjusted width to fit content */
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }

  .sliderWrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .slide {
    display: flex; /* Use Flexbox to align content side by side */
    align-items: center;
    justify-content: flex-start; /* Align items to the start */
    min-width: 100%; /* Full width for each slide */
    box-sizing: border-box;
    color: #fff;
    text-align: left; /* Align text to the left */
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .slideContent {
    flex: 1;
    padding-left: 2rem; /* Add space between image and text */
  }

  .HomeCustomersReview-sliderImage {
    width: 25vw; /* Fixed width for the image */
    height: auto; /* Maintain aspect ratio */
    border: 4px solid #f1f1f1;
    border-radius: 220px;
    @media (min-width: 1800px) {
      width: 21vw;
    }
  }
  .HomeCustomersReview-sliderAnchor {
    width: auto; /* Fixed width for the image */
    height: auto; /* Maintain aspect ratio */
  }

  .HomeCustomersReviewHeading {
    color: #fff;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: bolder;
    @media (max-width: 768px) {
      padding-top: 12px;
    }
  }
  .HomeCustomersReviewHeadingTwo {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: bolder;
    padding-top: 12px;
    @media (max-width: 768px) {
      padding-top: 12px;
    }
  }

  .HomeCustomersReviewSubheading {
    color: #d4d4d4;
    margin-bottom: 0.5rem;
  }

  .HomeCustomersReviewPara {
    padding-top: 32px;
    color: #fff;
    margin-bottom: 1rem;
    line-height: 28px;
  }

  .dots {
    text-align: center;
    padding-top: 10px;
  }

  .dot {
    height: 9px;
    width: 9px;
    margin: 0 5px;
    border: 2px solid #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease, transform 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.4);
      background-color: #bbb;
    }
  }

  .active {
    background-color: #bbb;
    transform: scale(1.4);
  }
`

export const AppHomeCustomersReview = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const slides = [
    {
      heading: 'Patrick Reymann',
      headingTwo: 'IDC',
      subheading: 'Research Director, Procurement and Enterprise Applications',
      para: '“Raindrop’s leadership team of experienced procurement professionals have built a truly disruptive enterprise spend management solution with the end user in mind. Raindrop’s impressive speed to value attainment, ease of use, and intuitive and clean user interface are true differentiators that deserve strong consideration.”',
      imageSrc: 'https://storage.googleapis.com/raindroppublic/website_data/Patrick-Reymann-IDC.png',
      link: 'https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAAa3cSsBEOWS9vGL7OKc8BcbO_DjUYuqnB8&keywords=patrick%20reymann&origin=RICH_QUERY_SUGGESTION&position=0&searchId=54295d2e-0bc9-4473-b877-98bdfa2d7782&sid=F~w&spellCorrectionEnabled=false', // Add link here
    },
    {
      heading: 'Nikhil Gaur',
      headingTwo: 'Spend Matters',
      subheading: 'Director, Strategic Projects & Research Analyst',
      para: '"Raindrop is an easy-to-use S2P provider that emphasizes collaboration and visibility. It fills a needed role as a US-based mid-market and enterprise suite, and in the past year the provider has made several additions to the modules to make them more robust."',
      imageSrc: 'https://storage.googleapis.com/raindroppublic/website_data/Nikhil-Gaur-Spendmatters.png',
      link: 'https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAACTViQ4B-thrMnYVpKmATKJhIB5w4Omoik4&keywords=nikhil%20gaur&origin=RICH_QUERY_SUGGESTION&position=0&searchId=b12af901-e884-426c-8b0b-40a937a7e213&sid=5PP&spellCorrectionEnabled=false', // Add link here
    },
  ]

  const numOfSlides = slides.length

  // Automatically change slides every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % numOfSlides)
    }, 20000)

    return () => clearInterval(interval)
  }, [numOfSlides])

  const handleDotClick = (index: number) => {
    setCurrentSlideIndex(index)
  }

  return (
    <AppHomeCustomersReviewWpper>
      <div className="HomeCustomersReviewContain">
        <div className="sliderContainer">
          <div
            className="sliderWrapper"
            style={{
              transform: `translateX(-${currentSlideIndex * 100}%)`, // Full width for each slide
            }}
          >
            {slides.map((slide, i) => (
              <div className="slide" key={i}>
                <a
                  href={slide.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="HomeCustomersReview-sliderAnchor"
                >
                  <img src={slide.imageSrc} alt={`Slide ${i + 1}`} className="HomeCustomersReview-sliderImage" />
                </a>
                <div className="slideContent">
                  <div className="HomeCustomersReviewHeading">{slide.heading}</div>
                  <div className="HomeCustomersReviewHeadingTwo">{slide.headingTwo}</div>
                  <div className="HomeCustomersReviewSubheading">{slide.subheading}</div>
                  <div className="HomeCustomersReviewPara">{slide.para}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="dots">
          {Array.from({ length: numOfSlides }).map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlideIndex ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
    </AppHomeCustomersReviewWpper>
  )
}
