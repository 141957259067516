import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
// import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHOneTag } from './ReUseHOneTag'
import { DemoRequestButton } from './DemoRequestButton'

const AppPayableBookDemoWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .CompanyPayableBannerSection {
    height: 76vh;
    background-image: url('https://storage.googleapis.com/raindroppublic/website_data/payables_bg.png');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 0px 50px 0px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1390px) {
      padding: 80px 30px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .CompanyPayableHeading {
    width: 100vw;
    text-align: center;
    margin-top: 55px;
    font-size: 66px;
    color: #fff;
    /* padding-top: 15%; */
    @media (max-width: 1650px) {
      padding-top: 0%;
      margin-top: 55px;
    }
    @media (max-width: 1090px) {
      padding-top: 35px;
    }
    @media (max-width: 480px) {
      font-size: 42px;
    }
  }
  .NewsBannerImageSection {
    width: 50%;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .companyPayableBannerImage {
    width: 100%;
    height: auto;
  }
  .CustomerBannerPara {
    width: 100vw;
    text-align: center;
    color: #fff;
    /* margin: 40px 800px 0px 0px; */
    @media (max-width: 1650px) {
      /* margin-right: 20%; */
    }
    @media (max-width: 1090px) {
      /* margin-right: 20px; */
    }
    @media (max-width: 1920px) {
      /* margin-right: 40%; */
    }
  }
  .NewsBannerImageSection {
    width: 45%;
  }
  .CompanyPayableBannerContentSection {
    width: 50%;
    @media (max-width: 1920px) {
      width: 40%;
    }
    @media (max-width: 1090px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    @media (max-width: 425px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  .payableDemoButton {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: auto; */
    padding-bottom: 16px;
  }
`

export const AppPayableBookDemo = () => {
  return (
    <AppPayableBookDemoWapper>
      <div className="CompanyPayableBannerSection">
        <div className="CompanyPayableBannerContentSection">
          <ReUseHOneTag Heading="Let's get more productive" HeadingStyle="CompanyPayableHeading" />
          <ReUsePtag
            para="Learn how you can benefit from real-time Actionable Intelligence with Raindrop Payables."
            paraStyle="CustomerBannerPara "
          />
          <div className="payableDemoButton">
            <DemoRequestButton label="Talk To Us" />
          </div>
        </div>
        {/* <div className="NewsBannerImageSection">
          <ReuseImgTag
            ImageStyle="companyPayableBannerImage"
            ImagePath="/img/partners-banner.png"
            AltName="Company-partners-Banner-image"
          />
        </div> */}
      </div>
    </AppPayableBookDemoWapper>
  )
}
