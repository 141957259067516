import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppPayablesSecondSectionWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .PayablesHeading {
    font-size: 92px;
    color: #34a1d5;
    /* text-align: left; */
    /* padding: 0px 0px 10px 0px; */
  }
  .PayablesKeyPara {
    /* text-align: left; */
    max-width: 1200px;
    /* margin: auto; */
    display: flex;
    font-weight: bolder;
    color: #fff;
    /* padding-top: 24px; */
    /* padding-bottom: 50px; */
    /* margin: 0px 200px 0px 200px; */
    @media (max-width: 1090px) {
      padding: 0px 15px 50px 15px;
    }
  }
  /* @media (max-width: 1920px) {
      padding: 0px 15px 50px 15px;
      margin: 0px 0px 0px 400px;
    }
    @media (max-width: 1650px) {
      padding: 0px 15px 50px 15px;
      margin: 0px 0px 0px 250px;
    }
    @media (max-width: 1440px) {
      padding: 0px 15px 50px 15px;
      margin: 0px 180px 0px 180px;
    } */

  .PayablesSecondSectionContainer {
    /* background-image: url(/img/payable-banner-3.png);
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; */
    height: 33vh;
    background-color: #021d2d;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    @media (max-width: 440px) {
      display: flex;
      flex-direction: column;
      height: auto;
    }
  }
`

export const AppPayablesSecondSection = () => {
  return (
    <AppPayablesSecondSectionWapper>
      <div className="PayablesSecondSectionContainer">
        <div className="PayablesSecondSection">
          <ReUsePtag para="Reduce Invoice Processing Costs by" paraStyle="PayablesKeyPara" />
          <ReUseHTwotag Heading="70%" HeadingStyle="PayablesHeading" />
        </div>
        <div className="PayablesSecondSection">
          <ReUsePtag para="Achieve Touchless Invoice Approval Rates up to" paraStyle="PayablesKeyPara" />
          <ReUseHTwotag Heading="90%" HeadingStyle="PayablesHeading" />
        </div>
        <div className="PayablesSecondSection">
          <ReUsePtag para="Gain Cycle Time Improvement by" paraStyle="PayablesKeyPara" />
          <ReUseHTwotag Heading="85%" HeadingStyle="PayablesHeading" />
        </div>
      </div>
    </AppPayablesSecondSectionWapper>
  )
}
